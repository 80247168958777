<template>
    <div class="container">
        <b-modal title="Images" id="imgModal" no-close-on-esc hide-footer @hidden="model={}">
            <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                    <div class="row">
                        <div class="col-md-12 form-group">
                            <b-form-file  alternative name="Image" label="Image" v-model="model.file_name"
                                         :rules="rules.image"/>
                        </div>
                        <div class="col-md-12">
                            <b-button type="submit" variant="success">
                                Save
                            </b-button>
                        </div>

                    </div>
                </b-form>
            </validation-observer>
        </b-modal>

        <div class="mt-4">
            <b-card-title>
                <b-button variant="success" size="sm" class="float-right" @click="addImageItem()">New Image</b-button>
                <h3>Images</h3>
            </b-card-title>

<div class="responsive-table">
            <vue-table :data="data" :fields="fields" :url="url" ref="table" :per-page="5">
                <template #image="{rowData}">
                    <img :src="rowData.file_name" style="max-width: 50px"
                         class="rounded-circle" alt="">
                </template>
                <template #action="{rowData}">
                    <b-button-group>
                        <b-button size="sm" variant="danger" @click="deleteItem(rowData)">Delete</b-button>
                    </b-button-group>
                </template>
            </vue-table>
</div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'secure-axios';
import urls from '@data/urls';

export default {
    name: 'ProductImages',
    components: {},
    props: {
        product: { type: String, required: true }
    },
    data () {
        return {
            model: {
                product_master_id: this.product,
                file_name: []
            },
            rules: {
                image: {
                    required: true
                }
            },
            // need to update //
            url: urls.productImages.list + '?product_master_id=' + this.product,
            data: [],
            fields: [
                {
                    name: 'id',
                    title: 'ID',
                    sortField: 'id'
                },
                {
                    name: '__slot:image',
                    title: 'Image'
                },
                {
                    name: '__slot:action',
                    title: 'Action'
                }
            ]
        };
    },

    methods: {
        openModal () {
            this.$bvModal.show('imgModal');
        },

        setData (response) {
            this.$refs.table.refreshTable();
        },

        async onSubmit () {
            const url = urls.productImages.create;
            console.log(this.model.file_name);
            const response = await axios.form(url, this.model);
            if (response.data.error) {
                this.$refs.formValidator.setErrors(response.data.errors);
            } else {
                this.setData(response);
                this.$bvModal.hide('imgModal');
            }
        },

        async deleteItem (rowData) {
            const confirm = await this.$bvModal.msgBoxConfirm('Are you sure ?');
            if (confirm) {
                const params = { id: rowData.id };
                // need to update the url //
                const response = await axios.form(urls.productImages.delete, params);
                this.setData(response);
            }
        },
        async addImageItem () {
            this.imgModal = {
                id: this.product
            };
            this.openModal();
        }
    }
};

</script>
